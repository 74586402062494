import { useEffect } from 'react'

/**
 * Custom hook for locking the body scroll
 */
export const useBodyScrollLock = (lock = false) => {
  useEffect(() => {
    const body = document.body
    const originalOverflow = body.style.overflow

    if (lock) {
      body.style.overflow = 'hidden'
    }

    return () => {
      body.style.overflow = originalOverflow
    }
  }, [lock])
}
