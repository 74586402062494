import { getAppConfig } from './appConfig'
import type { AppEnv } from './envs'
import { browserEnvs } from './envs'

export interface ClientSideSafeConfigs {
  consent: {
    openWeb: {
      templateId: string
    }
    loggedIn: {
      templateId: string
      channel: string
    }
  }
  ciam: {
    providerId: string
  }
  seLegacyApp: {
    url: string
  }
}

export const getClientSafeConfig = (env: AppEnv): ClientSideSafeConfigs => {
  const config = getAppConfig(env, browserEnvs.NEXT_PUBLIC_COUNTRY, false)

  // Add variables that can be safely used in client-side components here
  return {
    consent: {
      openWeb: {
        templateId: config.consent.openWeb.templateId,
      },
      loggedIn: {
        templateId: config.consent.loggedIn.templateId,
        channel: config.consent.loggedIn.channel,
      },
    },
    ciam: {
      providerId: config.ciam.providerId,
    },
    seLegacyApp: {
      url: config.seLegacyApp.url,
    },
  }
}
