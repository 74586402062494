import { signOut } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import type { DefaultTheme } from 'styled-components/dist/types'

import {
  Box,
  Button,
  IconExpandLess,
  IconExpandMore,
  IconSettings,
  IconSignOut,
  ListItems,
  fontWeights,
  px2rem,
  spacing,
  styles,
  useOutsideClickClose,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { useWindowScroll } from '@/shared/hooks/useWindowScroll'
import { usePathname, type useRouter } from '@/shared/navigation'
import { routes } from '@/shared/routes'
import { federatedLogOut } from '@/shared/serverActions/federatedLogOut'
import { getClickColor, getHoverColor } from '@/shared/utils/colors'

type UserBadgeProps = {
  name: string
  padding?: string
  isDropdown: boolean
  router: ReturnType<typeof useRouter>
  setNavigationZIndex?: (zIndex: number) => void
  megaMenuIsOpen?: boolean
}

const logOut = async () => {
  const isLoggedOut = await federatedLogOut()
  void signOut({
    callbackUrl: `${countryConfig.basePath}/?loggedInMessage=${isLoggedOut ? 'ok' : 'LogoutError'}`,
    redirect: true,
  })
}

export const UserBadge = ({
  name,
  padding,
  isDropdown,
  router,
  setNavigationZIndex,
  megaMenuIsOpen,
}: UserBadgeProps) => {
  const pathname = usePathname()
  const theme = useTheme()
  const megaMenuRef = useRef(megaMenuIsOpen)
  const t = useTranslations('mainNavigation')
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [position, setPosition] = React.useState(0)

  const { y } = useWindowScroll()

  const { wrapperRef } = useOutsideClickClose(() => {
    setTimeout(() => setIsMenuOpen(false), 100)
  }, !isMenuOpen)

  const items = [
    {
      name: t('mySettings'),
      value: 'mysettings',
      icon: IconSettings,
    },
    {
      name: t('logout'),
      value: 'logout',
      icon: IconSignOut,
    },
  ]

  const handleClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
      !megaMenuIsOpen && changeZIndex(90)
    } else {
      setPosition(window.scrollY)
      setIsMenuOpen(true)
      changeZIndex(100)
    }
  }

  const changeZIndex = (zIndex: number) => {
    setNavigationZIndex && setNavigationZIndex(zIndex)
  }

  const handleSelect = async (value: string) => {
    if (value === 'mysettings') {
      setIsMenuOpen(false)
      router.push('/my/settings')
    }
    if (value === 'logout') {
      await logOut()
    }
  }

  useEffect(() => {
    megaMenuRef.current = megaMenuIsOpen
  }, [megaMenuIsOpen])

  useEffect(() => {
    if (isMenuOpen && y !== position) {
      setIsMenuOpen(false)
      !megaMenuIsOpen && changeZIndex(90)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y])

  useEffect(() => {
    setTimeout(() => {
      !isMenuOpen && !megaMenuRef.current && changeZIndex(90)
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen])

  return (
    <Box
      ph={isDropdown ? 0 : padding}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      height="100%"
      boxRef={wrapperRef}
      width="fit-content"
      zIndex={200}
    >
      {isDropdown ? (
        <Box>
          <StyledButton
            ph={padding}
            onClick={handleClick}
            status="plain"
            color={theme.colors.textPrimary}
          >
            <ButtonBadge
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily="FortumSans"
              className="button-badge"
              theme={theme}
            >
              {name[0]}
            </ButtonBadge>
            <Icon icon={isMenuOpen ? IconExpandLess : IconExpandMore} />
          </StyledButton>
          <StyledBox position="absolute" bottom="0" right={0} zIndex={100}>
            <StyledListItems
              items={items}
              onSelect={(value) => handleSelect(value as string)}
              onClose={() => !megaMenuIsOpen && changeZIndex(90)}
              opened={isMenuOpen}
              width="fit-content"
              boxShadow={`0 0 ${px2rem(27)} ${px2rem(8)} rgba(0, 0, 0, 0.2)`}
              disableBorders
              selected={pathname === routes.LOGGED_IN_SETTINGS ? 'mysettings' : ''}
              highlightSelected
              borderRadius={`0 0 ${spacing.xxxxs} ${spacing.xxxxs} !important`}
            />
          </StyledBox>
        </Box>
      ) : (
        <StyledLink href="/my/overview" noUnderline noUnderlineHover>
          <Box display="flex">
            <Badge
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily="FortumSans"
            >
              {name[0]}
            </Badge>
          </Box>
        </StyledLink>
      )}
    </Box>
  )
}

const badgeStyles = (theme: DefaultTheme) => css`
  width: 2rem;
  height: 2rem;
  background-color: ${theme.colors.textPositive};
  color: ${theme.colors.textLightOnDark};
  border-radius: 50%;
`

const Badge = styled(Box)`
  ${({ theme }) => css`
    ${badgeStyles(theme)}
    &:hover {
      background: ${getHoverColor(theme, theme.colors.textPositive)};
    }

    &:active {
      background: ${getClickColor(theme, theme.colors.textPositive)};
    }
  `}
`

const ButtonBadge = styled(Box)`
  ${({ theme }) => css`
    ${badgeStyles(theme)}
    margin-right: ${spacing.xxxs};
    ${styles.breakpoint.xl} {
      margin-right: ${spacing.xxs};
    }
  `}
`

const StyledBox = styled(Box)`
  & > div {
    width: auto;
    right: 0;
  }
`

const StyledListItems = styled(ListItems)`
  width: auto;

  label span {
    font-size: 1rem;
  }

  label span:nth-child(2) {
    padding-left: 0;
    padding-right: 1rem;
    font-weight: ${fontWeights.default};
  }
`

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    span {
      display: flex;
    }

    &:hover {
      background: none;

      .button-badge {
        background: ${getHoverColor(theme, theme.colors.textPositive)};
      }
    }

    &:active {
      background: none;

      .button-badge {
        background: ${getClickColor(theme, theme.colors.textPositive)};
      }
    }
  `}
`
