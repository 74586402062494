'use client'

import { useTranslations } from 'next-intl'
import React, { useEffect, useState } from 'react'

import { NotificationBanner } from '@/shared/components/NotificationBanner'

import supportedBrowsers from './supportedBrowsers'

export const BrowserSupportChecker = () => {
  const [isSupported, setIsSupported] = useState<boolean | null>(null)
  const t = useTranslations('browserSupport')

  useEffect(() => {
    const checkBrowserSupport = () => {
      const userAgent = navigator.userAgent
      const isSupportedBrowser = new RegExp(supportedBrowsers).test(userAgent)
      setIsSupported(isSupportedBrowser)
    }

    checkBrowserSupport()
  }, [])

  if (isSupported === null) {
    return null
  }

  return !isSupported && <NotificationBanner type="warning" message={t('browserNotSupported')} />
}
