'use client'

import { useTranslations } from 'next-intl'
import type { FC } from 'react'
import { useState } from 'react'

import { Box, IconCheck, IconError, IconInfo, Notification, spacing } from '@fortum/elemental-ui'

import { LoggedInRichText } from '@/logged-in/components/LoggedInRichText'
import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Theme } from '@/shared/providers/theme'

import type { RichTextDocument } from '../RichText'

type ServiceAnnouncementStyle = {
  background: keyof Theme['colors']
  textColor: keyof Theme['colors']
  icon: FC
}

const TypeStyles = {
  Warning: {
    background: 'backgroundWarning',
    textColor: 'textWarning',
    icon: IconInfo,
  },
  Error: {
    background: 'backgroundAlert',
    textColor: 'textAlert',
    icon: IconError,
  },
  General: {
    background: 'backgroundSuccess',
    textColor: 'textSuccess',
    icon: IconCheck,
  },
  Info: {
    background: 'backgroundInfo',
    textColor: 'textInfo',
    icon: IconInfo,
  },
} satisfies Record<string, ServiceAnnouncementStyle>

export type AnnouncementStyleType = keyof typeof TypeStyles
type ServiceAnnouncementBannerProps = {
  type: AnnouncementStyleType
  message: RichTextDocument | undefined | null
  hideCloseButton: boolean
  title: string
  handleClose: () => void
}

export const ServiceAnnouncementBanner: FC<ServiceAnnouncementBannerProps> = ({
  type,
  message,
  hideCloseButton,
  title,
  handleClose,
}) => {
  const theme = useTheme()

  const t = useTranslations('serviceNotification')
  const [isClosed, setIsClosed] = useState(false)
  const { background, textColor, icon } = TypeStyles[type]
  const updatedHandleClose = () => {
    handleClose()
    setIsClosed(true)
  }

  return (
    <Box
      width="100%"
      background={theme.colors[background]}
      style={{ marginTop: isClosed ? '0' : spacing.xs }}
    >
      <Notification
        heading={title}
        iconButtonProps={{ 'aria-label': t('iconAriaLabel') }}
        backgroundColor={theme.colors[background]}
        textColor={theme.colors[textColor]}
        icon={<Icon icon={icon} marginRight={spacing.xxs} />}
        hideCloseButton={hideCloseButton}
        readMoreText={t('readMoreText')}
        onClose={updatedHandleClose}
      >
        <LoggedInRichText content={message} linkColor={theme.colors[textColor]} />
      </Notification>
    </Box>
  )
}
