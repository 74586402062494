import { httpBatchLink } from '@trpc/client'
import superjson from 'superjson'

import { countryConfig } from '@/shared/countryConfig'
import { trpc } from '@/shared/trpc/react'

const url = `${countryConfig.basePath}/api/trpc/`

export const getTrpcClient = () =>
  trpc.createClient({
    links: [
      httpBatchLink({
        url,
      }),
    ],
    transformer: superjson,
  })
