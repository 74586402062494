'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { TRPCClientError } from '@trpc/client'
import { useState } from 'react'

import { useRouter } from '@/shared/navigation'
import { trpc } from '@/shared/trpc/react'
import { getTrpcClient } from '@/shared/utils/trpc/getTrpcClient'

import type { AppRouter } from '../trpc/routers/app'

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter()

  const redirectOnAuthError = (e: unknown) => {
    const error = e as TRPCClientError<AppRouter>
    if (error.data?.code === 'UNAUTHORIZED' || error.message === 'UNAUTHORIZED') {
      router.push(`/sign-out?loggedInMessage=${encodeURIComponent('TokenExpired')}`)
    }
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Number.POSITIVE_INFINITY,
            onError: (e) => {
              redirectOnAuthError(e)
            },
          },
          mutations: {
            onError: (e) => {
              redirectOnAuthError(e)
            },
          },
        },
      }),
  )

  const [trpcClient] = useState(() => getTrpcClient())

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
