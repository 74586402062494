'use client'

import styled from 'styled-components'

import { Box, Col, Row, Section, colors } from '@fortum/elemental-ui'

import { range } from '@/shared/utils/array'

/**
 * Helper component that overlays an Elemental 12-column grid on top of the page.
 * This can be used to help make sure components are aligned correctly.
 */
export const GridHelper = () => (
  <StyledSection position="fixed" top="0" zIndex="100" width="100%" pv={0}>
    <Row>
      {range(12).map((i) => (
        <Col xxs={1} key={i}>
          <Box height="100dvh" background={colors.fogGrey} opacity={0.1} />
        </Col>
      ))}
    </Row>
  </StyledSection>
)

const StyledSection = styled(Section)`
  pointer-events: none;
`
