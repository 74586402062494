'use client'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'

import { ServiceAnnouncementList } from '@/shared/components/ServiceAnnouncementList/ServiceAnnouncementList'
import {
  isTypeServiceAnnouncementLoggedIn,
  isTypeServiceAnnouncementOpenWeb,
} from '@/shared/contentful/types/contentTypeGuards'
import { usePathname } from '@/shared/navigation'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'

import type {
  AnyServiceAnnouncement,
  ServiceAnnouncementWithPageUrl,
} from './serviceAnnouncementUtils'
import { getFullPathForOpenWebPages } from './serviceAnnouncementUtils'

const getKeyForAnnouncement = (id: string) => `gw-li.announcement-${id}`

const groupByPageUrl = (announcementWithPageUrls: ServiceAnnouncementWithPageUrl[]) => {
  return announcementWithPageUrls.reduce(
    (acc, curr) => {
      const page = curr.pageUrl
      acc[page] = [...(acc[page] ?? []), curr]
      return acc
    },
    {} as Record<string, ServiceAnnouncementWithPageUrl[]>,
  )
}

type ServiceAnnouncementProps = {
  serviceAnnouncements: AnyServiceAnnouncement[]
}

export const ServiceAnnouncementNotification: FC<ServiceAnnouncementProps> = ({
  serviceAnnouncements,
}) => {
  const pathname = usePathname()
  const [announcements, setAnnouncements] = useState<ServiceAnnouncementWithPageUrl[]>([])

  const currentPage = '/' + (pathname.split('/').at(-1) ?? '')

  const announcementWithPagesUrls = useMemo(() => {
    return serviceAnnouncements.flatMap((announcement) => {
      const announcementPages = isTypeServiceAnnouncementLoggedIn(announcement)
        ? (announcement.loggedInPages ?? [])
        : isTypeServiceAnnouncementOpenWeb(announcement) && !announcement.global
          ? getFullPathForOpenWebPages(announcement.pagesCollection)
          : []

      return announcementPages.map((page) => {
        return {
          name: announcement.name,
          title: announcement.title,
          content: announcement.content,
          type: announcement.type,
          hideCloseButton: announcement.hideCloseButton,
          pageUrl: page || '',
          id: announcement.sys.id,
        } satisfies ServiceAnnouncementWithPageUrl
      })
    })
  }, [serviceAnnouncements])

  const globalAnnouncements = useMemo(() => {
    return serviceAnnouncements.flatMap((announcement) => {
      if (isTypeServiceAnnouncementOpenWeb(announcement) && announcement.global) {
        return {
          name: announcement.name,
          title: announcement.title,
          content: announcement.content,
          type: announcement.type,
          hideCloseButton: announcement.hideCloseButton,
          pageUrl: '*',
          id: announcement.sys.id,
        } satisfies ServiceAnnouncementWithPageUrl
      }
      return []
    })
  }, [serviceAnnouncements])

  const groupedByPageUrl = useMemo(() => {
    return groupByPageUrl(announcementWithPagesUrls)
  }, [announcementWithPagesUrls])

  useEffect(() => {
    const announcementsForCurrentPage = groupedByPageUrl[currentPage] ?? []

    const allVisibleAnnouncements = [...announcementsForCurrentPage, ...globalAnnouncements]

    if (!allVisibleAnnouncements) {
      return
    }
    setAnnouncements(
      allVisibleAnnouncements.filter((announcement) => {
        return (
          getLocalStorageValue(getKeyForAnnouncement(announcement.id), z.string(), 'false') ===
          'false'
        )
      }),
    )
  }, [groupedByPageUrl, currentPage, globalAnnouncements])

  if (serviceAnnouncements.length === 0) {
    return null
  }

  const onClose = (id: ServiceAnnouncementWithPageUrl['id']) => {
    setLocalStorageValue(getKeyForAnnouncement(id), 'true')
    setAnnouncements((announcements) =>
      announcements.filter((announcement) => announcement.id !== id),
    )
  }

  return <ServiceAnnouncementList announcements={announcements} onClose={onClose} />
}
