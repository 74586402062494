import type React from 'react'
import styled, { css } from 'styled-components'

import { Box, ContentText, hex2rgba, spacing, styles } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'

/**
 * TODO:
 *      Better type safety
 */

export type MenuLinkProps = {
  /**
   * Text of the button
   */
  primaryText: string
  /**
   * Url of the button link
   */
  url?: string
  /**
   * Url target, should the site open in different window or the same
   */
  target?: string
  /**
   * Title of the button link
   */
  title?: string
  /**
   * Flag to specify if button is active
   */
  active?: boolean
  /**
   * Data test id
   */
  dataTestId?: string
}

/**
 * Common use button for navigation menu in all container based variants
 */
export const MenuLink = ({
  primaryText,
  url,
  target,
  title,
  active = false,
  dataTestId,
}: MenuLinkProps) => {
  if (!url) {
    return
  }

  // Function to handle key down events on the box
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      if (target === '_blank') {
        window.open(url, '_blank')
      } else {
        window.location.href = url
      }
    }
  }

  return (
    <StyledLink
      data-testid={dataTestId}
      tabIndex={-1}
      href={url}
      target={target}
      aria-label={primaryText}
      title={title}
      noUnderlineHover
    >
      <EffectBox $isActive={active}>
        <Box
          role="link"
          display="flex"
          marginHorizontal={{ default: `-${spacing.xs}`, xl: 0 }}
          ph={{ default: spacing.xs, xl: spacing.xxs }}
          pv={{ default: spacing.xxs }}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <StyledText>{primaryText}</StyledText>
        </Box>
      </EffectBox>
    </StyledLink>
  )
}

const EffectBox = styled(Box)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => css`
    ${$isActive
      ? css`
          div {
            border-left: ${spacing.xxxxs} solid ${theme.colors.brandPrimary};
            background-color: ${hex2rgba(theme.colors.brandPrimary, 0.05)};
          }
        `
      : ``}

    :hover,
    :focus,
    :active {
      background-color: ${hex2rgba(theme.colors.brandPrimary, 0.05)};

      p {
        color: ${theme.colors.textPrimary};
        background: none;
      }
    }
  `}
`

const StyledText = styled(ContentText)`
  ${({ theme }) => css`
    ${styles.breakpoint.xs} {
      color: ${theme.colors.textPrimary};
    }
    ${styles.breakpoint.xl} {
      color: ${theme.colors.textSecondary};
    }
  `}
`
