import type { FC, PropsWithChildren } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import type { Theme as ElementalTheme } from '@fortum/elemental-ui'
import { ThemeProvider as ElementalThemeProvider, generateLegacyTheme } from '@fortum/elemental-ui'

import type { CosColor } from '@/shared/utils/colors'
import { colors, darkColors, lightColors } from '@/shared/utils/colors'

import { browserEnvs } from '../envs'

export type Theme = ElementalTheme & {
  colors: ElementalTheme['colors'] & typeof colors & CosColor
}

export const elementalLightTheme = generateLegacyTheme('light')
export const elementalDarkTheme = generateLegacyTheme('dark')

const createTheme = (elementalTheme: ElementalTheme, designColors: CosColor): Theme => ({
  ...elementalTheme,
  colors: {
    ...colors,
    ...elementalTheme.colors,
    ...designColors,
  },
})

const lightTheme = createTheme(elementalLightTheme, lightColors)
const darkTheme = createTheme(elementalDarkTheme, darkColors)

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  // Dark mode is not officially supported, but it can be tested by setting this env variable
  // For actual support, this should be based on the user system setting or a user preference
  const isDarkMode = browserEnvs.NEXT_PUBLIC_DARK_MODE
  const currentTheme = isDarkMode ? darkTheme : lightTheme

  return (
    <SCThemeProvider theme={currentTheme}>
      <ElementalThemeProvider theme={currentTheme}>{children}</ElementalThemeProvider>
    </SCThemeProvider>
  )
}
