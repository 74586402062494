import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Hidden, usePopover } from '@fortum/elemental-ui'

import type { LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'
import { isTypeLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import type { useRouter } from '@/shared/navigation'

import { MainNavigation } from './MainNavigation'
import { TopNavigation } from './TopNavigation'
import { useBodyScrollLock } from '../NavigationMobile/SliderMenu/utils'
import { useCloseOnPathnameChange } from '../parts/hooks/useCloseOnPathnameChange'
import { ShadowOverlay } from '../parts/ShadowOverlay'

type NavigationDesktopProps = {
  /**
   * Top navigation items
   */
  topNavigation: LinkEntry[]
  /**
   * Main navigation items
   */
  mainNavigation: MainNavigationEntry
  /**
   * Localized pathname of the current page
   */
  pathname: string
  /**
   * Username of the logged in user
   */
  username: string | undefined
  /**
   * Flag indicating if the current page is private
   */
  isPrivate: boolean
  /**
   * Router instance
   */
  router: ReturnType<typeof useRouter>
  /**
   * Open search callback
   */
  onOpenSearch: () => void
}

/**
 * Component responsible for desktop navigation
 */
export const NavigationDesktop = ({
  topNavigation,
  mainNavigation,
  pathname,
  username,
  isPrivate,
  router,
  onOpenSearch,
}: NavigationDesktopProps) => {
  const [selectedHeaderItem, setSelectedHeaderItem] = useState<string>()
  const [isShadowOverlay, setIsShadowOverlay] = useState(false)
  const [isTopBarVisible, setIsTopBarVisible] = useState(true)
  const openWebUserLoginButtonPopover = usePopover()
  const [height, setHeight] = useState<string | number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useCloseOnPathnameChange(() => setSelectedHeaderItem(undefined))

  // Check window object to prevent errors happening during SSR
  const resizeObserverRef = useRef(
    typeof window !== 'undefined'
      ? new ResizeObserver((entries: ResizeObserverEntry[]) =>
          setHeight(`${entries[0].borderBoxSize[0].blockSize}px`),
        )
      : null,
  )
  const observerTarget = useRef(null)

  useBodyScrollLock(Boolean(selectedHeaderItem))

  const handleHeaderMenuItemClick = (name: string) => {
    const value = name !== selectedHeaderItem ? name : undefined
    setSelectedHeaderItem(value)
  }

  const handleClickShadowOverlay = (e: MouseEvent) => {
    e.stopPropagation()
    openWebUserLoginButtonPopover.handleClose()
    setSelectedHeaderItem(undefined)
  }

  useEffect(() => {
    selectedHeaderItem ? setIsShadowOverlay(true) : setIsShadowOverlay(false)
  }, [selectedHeaderItem])

  useEffect(() => {
    const observerRef = resizeObserverRef.current
    const ref = contentRef.current

    if (ref) {
      observerRef?.observe(ref)
    }

    return () => observerRef?.disconnect()
  }, [])

  /**
   * Hook that set information about TopBar visibility (intersection with viewPort)
   */
  useEffect(() => {
    const currentTarget = observerTarget.current

    const observer = new IntersectionObserver(
      (entries) =>
        entries[0].isIntersecting ? setIsTopBarVisible(true) : setIsTopBarVisible(false),
      { threshold: 1 },
    )

    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [observerTarget])

  const changeTab = (e: string | number) => {
    const item = mainNavigation?.itemsCollection?.items?.find((item) => item?.name === e)

    if (item && isTypeLink(item)) {
      const url = getLinkEntryUrl(item)
      url && router.push(url)
      setSelectedHeaderItem(undefined)
    } else {
      handleHeaderMenuItemClick(e as string)
    }
  }

  return (
    <>
      <Hidden below="xl">
        {isShadowOverlay && (
          <ShadowOverlay
            $isOpen={isShadowOverlay}
            $duration={300}
            data-testid="shadow-overlay"
            onClick={handleClickShadowOverlay}
          />
        )}
        <TopNavigation
          topNavigation={topNavigation}
          router={router}
          pathname={pathname}
          onOpenSearch={onOpenSearch}
        />
        <div ref={observerTarget} />
      </Hidden>

      <MainNavigation
        isTopBarVisible={isTopBarVisible}
        mainNavigation={mainNavigation}
        pathname={pathname}
        selectedHeaderItem={selectedHeaderItem}
        username={username}
        isPrivate={isPrivate}
        router={router}
        changeTab={changeTab}
        closeMenu={() => setSelectedHeaderItem(undefined)}
        height={height}
        contentRef={contentRef}
        onOpenSearch={onOpenSearch}
      />
    </>
  )
}
