'use client'

import { useSearchParams } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { NotificationBanner } from '@/shared/components/NotificationBanner'

type NotificationType = 'warning' | 'alert'

type NotificatinStatesType<T extends string = string> = {
  searchParam: string
  type: NotificationType
  message: T
}

type NotificationText = 'tokenExpired' | 'initializationError' | 'logoutError'

const notificationStates: NotificatinStatesType<NotificationText>[] = [
  {
    searchParam: 'TokenExpired',
    type: 'warning',
    message: 'tokenExpired',
  },
  {
    searchParam: 'InitializationError',
    type: 'alert',
    message: 'initializationError',
  },
  {
    searchParam: 'LogoutError',
    type: 'warning',
    message: 'logoutError',
  },
]

/**
 * A component to show a notification message
 */
export const LoggedInNotification = () => {
  const t = useTranslations('loggedInNotification')
  const searchParams = useSearchParams()
  const searchParam = searchParams?.get('loggedInMessage')

  if (!searchParam) {
    return null
  }

  const notificationState = notificationStates.find((state) => state.searchParam === searchParam)

  return (
    notificationState && (
      <NotificationBanner type={notificationState.type} message={t(notificationState.message)} />
    )
  )
}
