'use client'

import { useMemo } from 'react'

import { useMainLayoutContent } from '@/shared/providers/mainLayoutContent'

import { ServiceAnnouncementNotification } from './ServiceAnnouncementNotification'

export const ServiceAnnouncementNotificationConnector = () => {
  const { serviceAnnouncements: serviceAnnouncementsOpenWeb } = useMainLayoutContent()

  const serviceAnnouncements = useMemo(
    () => [...serviceAnnouncementsOpenWeb],
    [serviceAnnouncementsOpenWeb],
  )

  return <ServiceAnnouncementNotification serviceAnnouncements={serviceAnnouncements} />
}
