'use client'

import { Box, ButtonPill, ContentText, shadows, spacing } from '@fortum/elemental-ui'

import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { usePathname } from '@/shared/navigation'

/**
 * Banner shown when Contentful preview mode is active.
 */
export const PreviewIndicator = () => {
  const theme = useTheme()
  const pathname = usePathname()
  const redirectUrl = encodeURIComponent(pathname.replace('/preview', ''))

  return (
    <>
      <Box
        position="fixed"
        bottom={0}
        backgroundColor={theme.colors.background}
        zIndex={100}
        width="100%"
        padding={spacing.xxxs}
        boxShadow={shadows.s}
        justifyContent="center"
        display="flex"
        alignItems="center"
        gap={spacing.xxs}
      >
        <ContentText>Preview mode active</ContentText>
        <a href={countryConfig.basePath + `/api/draft/exit?redirect=${redirectUrl}`} tabIndex={-1}>
          <ButtonPill variant="condensed" size="s">
            Exit preview
          </ButtonPill>
        </a>
      </Box>
    </>
  )
}
