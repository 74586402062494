// ShadowOverlay.tsx
import styled, { css } from 'styled-components'

import { Box } from '@fortum/elemental-ui'

/**
 * Shadow overlay to be used in navigation when it is open
 */
export const ShadowOverlay = styled(Box)<{ $isOpen: boolean; $duration: number }>`
  ${({ $isOpen, $duration }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    top: 0;
    left: 0;
    transition:
      opacity ${$duration}ms ease-in-out,
      visibility ${$duration}ms ease-in-out;
    opacity: ${$isOpen ? 1 : 0};
    visibility: ${$isOpen ? 'visible' : 'hidden'};
    z-index: 99;
  `}
`
