'use client'

import { useTranslations } from 'next-intl'

import { IconLanguage, Menu, fontSizes, spacing } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { browserEnvs } from '@/shared/envs'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Locale } from '@/shared/locale'
import { getAvailableLocales, useLocale } from '@/shared/locale'
import { useRouter } from '@/shared/navigation'

/**
 * Main navigation language menu.
 */
export const LanguageMenu = () => {
  const theme = useTheme()
  const t = useTranslations('mainNavigation')
  const locale = useLocale()
  const router = useRouter()
  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const availableLocales = getAvailableLocales(country)

  // If there's only one locale available, don't show the menu
  if (availableLocales.length <= 1) {
    return null
  }

  const items = availableLocales.map((locale) => ({
    name: locale.toUpperCase(),
    value: locale,
  }))

  const selected = items.find((item) => item.value === locale)

  return (
    <Menu<Locale>
      icon={<Icon icon={IconLanguage} />}
      aria-label={t('languageSelection')}
      onSelect={(locale) => router.push('/', { locale })}
      selected={selected?.value}
      items={items}
      label={selected?.name ?? t('languageSelection')}
      variant="condensed"
      color={theme.colors.textLightOnDark}
      buttonProps={{
        fontSize: fontSizes.s.default,
        paddingHorizontal: spacing.xxs,
        borderRadius: 0,
      }}
    />
  )
}
