'use client'

import { useServerInsertedHTML } from 'next/navigation'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { ServerStyleSheet, StyleSheetManager } from 'styled-components'

/**
 * Source: https://nextjs.org/docs/app/building-your-application/styling/css-in-js#styled-components
 */
export const StyledComponentsRegistry = ({ children }: { children: ReactNode }) => {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet())

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - missing type in @types/styled-components
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    styledComponentsStyleSheet.instance.clearTag()
    return <>{styles}</>
  })

  if (typeof window !== 'undefined') {
    return <>{children}</>
  }

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>{children}</StyleSheetManager>
  )
}
