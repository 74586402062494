// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

/**
 * Hook that closes the menu when redirecting to another page
 */
export const useCloseOnPathnameChange = (callback: VoidFunction) => {
  // That is intended usage of next usePathname instead of next-intl which provide non localized pathname what means that for some dynamic pages non localized is the same
  // This behavior is something unwanted if we want to determinate when to close the menu depends on the path changed
  const localizedPathname = usePathname()

  useEffect(() => {
    callback()
  }, [localizedPathname])
}
