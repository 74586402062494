import type {
  ServiceAnnouncementLoggedInEntry,
  ServiceAnnouncementOpenWebEntry,
} from '@/shared/contentful/types'
import { getPageRoutePath } from '@/shared/contentful/utils'
import { rejectNil } from '@/shared/utils/array'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'

export type AnyServiceAnnouncement =
  | ServiceAnnouncementLoggedInEntry
  | ServiceAnnouncementOpenWebEntry

export type ServiceAnnouncementWithPageUrl = Pick<
  AnyServiceAnnouncement,
  'type' | 'hideCloseButton' | 'content' | 'name' | 'title'
> & {
  pageUrl: string
  id: string
}

export const getFullPathForOpenWebPages = (
  openWebPages: ServiceAnnouncementOpenWebEntry['pagesCollection'],
) =>
  openWebPages?.items?.filter(isNotNullOrUndefined).map((page) =>
    getPageRoutePath({
      slug: page.slug,
      __typename: page.__typename,
    }),
  ) ?? []

const hasMatchingVisibilityRules = (contentRules: string[], customerRules: string[]): boolean => {
  return contentRules.some((rule) => customerRules.includes(rule.toLocaleLowerCase()))
}

export const getVisibleServiceAnnouncements = (
  serviceAnnouncements: ServiceAnnouncementLoggedInEntry[],
  customerVisibilityRules: string[],
) => {
  return serviceAnnouncements.filter((announcement) => {
    const contentVisibilityRules = rejectNil(announcement.announcementVisibilityFlags ?? [])
    if (contentVisibilityRules.length === 0) {
      return announcement
    }
    if (hasMatchingVisibilityRules(contentVisibilityRules, customerVisibilityRules)) {
      return announcement
    }
  })
}
