import { useEffect, useState } from 'react'

import type { Breakpoint } from '@fortum/elemental-ui'
import { breakpoints } from '@fortum/elemental-ui'

export type ActiveBreakpoints = 'default' | keyof typeof breakpoints

export const getMediaQuery = (breakpoint?: Breakpoint): string | undefined =>
  breakpoint ? `screen and (min-width: ${breakpoints[breakpoint]}px)` : undefined

const MEDIA_QUERIES = {
  mobile: `screen and (max-width: ${breakpoints.s - 1}px)`,
  tablet: `screen and (min-width: ${breakpoints.s}px) and (max-width: ${breakpoints.l - 1}px)`,
  desktop: `screen and (min-width: ${breakpoints.l}px)`,
  desktopXL: `screen and (min-width: ${breakpoints.xl}px)`,
}

export const useMediaQuery = (query?: string): boolean => {
  const getMatches = (query?: string): boolean => {
    if (typeof window !== `undefined` && query) {
      return window.matchMedia(query).matches
    }
    return false
  }

  const [isMatched, setMatches] = useState<boolean>(getMatches(query))

  const handleChange = () => setMatches(getMatches(query))

  useEffect(() => {
    if (!query) {
      return
    }
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // addListener is a deprecated method but if it exists, it is used in order to support older browsers
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener(`change`, handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener(`change`, handleChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return isMatched
}

/**
 * @deprecated
 * This hook uses the browser only window api and thus breaks server-side-rendering.
 * The hook should only be used if rendering something server-side would become
 * really complicated. We should rather use elemental responsive props or css media queries.
 * The deprecation is a somewhat strong annotation here, so this can still be used in
 * cases where server-side only rendering would become really complicated.
 */
export const useBreakpoints = () => {
  const isMobile = useMediaQuery(MEDIA_QUERIES.mobile)
  const isTablet = useMediaQuery(MEDIA_QUERIES.tablet)
  const isDesktop = useMediaQuery(MEDIA_QUERIES.desktop)
  const isDesktopXL = useMediaQuery(MEDIA_QUERIES.desktopXL)

  const [activeBreakpoint, setActiveBreakpoint] = useState<ActiveBreakpoints>('default')

  useEffect(() => {
    const updateActiveBreakpoint = () => {
      const activeBreakpoint = getActiveBreakpoint(window.innerWidth)
      setActiveBreakpoint(activeBreakpoint)
    }

    window.addEventListener('resize', updateActiveBreakpoint)
    updateActiveBreakpoint()

    return () => {
      window.removeEventListener('resize', updateActiveBreakpoint)
    }
  }, [])

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopXL,
    activeBreakpoint,
  }
}
//
export const getActiveBreakpoint = (width: number) =>
  ([
    { breakpoint: 'default', condition: width < breakpoints.xs },
    { breakpoint: 'xs', condition: width < breakpoints.s },
    { breakpoint: 's', condition: width < breakpoints.m },
    { breakpoint: 'm', condition: width < breakpoints.l },
    { breakpoint: 'l', condition: width < breakpoints.xl },
    { breakpoint: 'xl', condition: width < breakpoints.xxl },
    { breakpoint: 'xxl', condition: width >= breakpoints.xxl },
  ].find((check) => check.condition)?.breakpoint as ActiveBreakpoints) ?? 'default'

export const useResponsiveValue = <T extends boolean | number | string>(
  value: T | { [key in ActiveBreakpoints]?: T },
): T => {
  const { activeBreakpoint } = useBreakpoints()

  // Define breakpoints in descending order
  const bpOrder: ActiveBreakpoints[] = ['xxl', 'xl', 'l', 'm', 's', 'xs', 'default']

  // Get current index in order
  const currentIndex = bpOrder.indexOf(activeBreakpoint)

  let fallbackBreakpoint: ActiveBreakpoints = activeBreakpoint

  // Logic for responsive value
  if (typeof value === 'object' && !(activeBreakpoint in value)) {
    fallbackBreakpoint = findFallbackBreakpoint(value, bpOrder, currentIndex)
  }

  return typeof value === 'object' ? value[fallbackBreakpoint]! : value
}

const findFallbackBreakpoint = (
  value: { [key in ActiveBreakpoints]?: boolean | number | string },
  bpOrder: ActiveBreakpoints[],
  currentIndex: number,
): ActiveBreakpoints => {
  const fallbackBreakpoint = bpOrder.slice(currentIndex).find((bp) => value[bp] !== undefined)

  return (fallbackBreakpoint as ActiveBreakpoints) ?? 'default'
}
