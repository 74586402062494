'use client'

import styled, { css } from 'styled-components'

import type { ButtonProps } from '@fortum/elemental-ui'
import { Button, colors as elementalColors, spacing } from '@fortum/elemental-ui'

export type StateButtonProps = ButtonProps & {
  /**
   * State of the button
   */
  isActive?: boolean
  /**
   * Colors configuration
   *
   * - active - determinate button color in active state
   * - default - determinate button color in not active state
   */
  colors?: ColorProps<'active' | 'default', never>
  /**
   * Set to true if You want to switch off all border styles eg. when hover or focus
   */
  isBorderVisible?: boolean
}

/**
 * Button with active state logic and fully customizable content
 */
export const StateButton = ({
  isActive,
  colors = { active: elementalColors.oceanGreen, default: elementalColors.lightGrey },
  children,
  padding = spacing.xxs,
  isBorderVisible,
  ...props
}: StateButtonProps) => {
  //If child is not typeof string but rather ReactElement then some Button styles overwriting is required
  const isChildrenNotText = typeof children !== 'string'

  return (
    //TODO: hex colors with opacity or rgba colors doesn't work
    <StyledButton
      $isChildrenNotText={isChildrenNotText}
      $isBorderVisible={isBorderVisible}
      p={padding}
      color={isActive ? colors.active : colors.default}
      height={isChildrenNotText ? 'auto' : undefined}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{ $isChildrenNotText?: boolean; $isBorderVisible?: boolean }>`
  ${({ $isBorderVisible }) =>
    $isBorderVisible &&
    css`
      & :focus,
      :focus-visible,
      :hover {
        border: none;
      }
    `}

  ${({ $isChildrenNotText, lineHeight }) =>
    $isChildrenNotText &&
    css`
      line-height: ${lineHeight || '1rem'};

      & > div {
        width: 100%;
        display: flow-root;

        > span {
          width: 100%;
        }
      }
    `}
`
