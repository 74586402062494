'use client'

// Add polyfills for the new ES2023 non-mutating array methods
// These are not yet include in Next.js by default
// https://nextjs.org/docs/architecture/supported-browsers#polyfills
// The methods are available in Node.js v20
import 'core-js/features/array/to-reversed'
import 'core-js/features/array/to-spliced'
import 'core-js/features/array/to-sorted'
import 'core-js/features/array/with'
