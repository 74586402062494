import styled, { css } from 'styled-components'

import { ContentText } from '@fortum/elemental-ui'

import * as styleMixins from '@/shared/style/styleMixins'

export const Text = styled(ContentText)<{ $maxLines: number }>`
  ${(props) => styleMixins.truncateMultiLineText(props.$maxLines)}
`

export const commonEventStyles = css`
  display: inline-block;

  :focus {
    outline: 0.13rem solid ${({ theme }) => theme.colors.primary};
  }

  :hover {
    background: ${({ theme }) => theme.colors.backgroundSecondary};
  }

  :active {
    background: ${({ theme }) => theme.colors.backgroundTertiary};
  }
`
