'use client'

import { createGlobalStyle } from 'styled-components'

import { focusOutline } from './styleMixins'

export const GlobalStyle = createGlobalStyle`
  /* Apply custom focus outline globally to certain elements */
  button:focus-visible,
  a:focus-visible,
  [tabindex='0']:focus-visible {
    ${focusOutline}
  }
`
