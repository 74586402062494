import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  ContentText,
  FortumLogo,
  IconArrowLeft,
  IconButton,
  IconChevronRight,
  IconCross,
  fontSizes,
  fontWeights,
  hex2rgba,
  spacing,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { StateButton } from '@/shared/components/StateButton'
import type { ColumnMenuEntry, LinkEntry, MainNavigationEntry } from '@/shared/contentful/types'
import { isTypeColumnMenu, isTypeLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import type { useRouter } from '@/shared/navigation'
import { Link } from '@/shared/navigation'

import { useBodyScrollLock } from './utils'
import { CatalogueContainer } from '../../parts/CatalogueContainer'
import { TopNavigationLink } from '../../parts/Links/TopNavigationLink'

type SliderMenuProps = {
  /**
   * State of the menu
   */
  isMenuOpened: boolean
  /**
   * Handler for closing the menu
   */
  handleCloseMenuLinkClick: () => void
  /**
   * Items to be displayed in the main navigation
   */
  mainNavigation: MainNavigationEntry
  /**
   * Links to be displayed in the top navigation
   */
  topNavigation: LinkEntry[]
  /**
   * Pathname of the current page
   */
  pathname: string
  /**
   * Router instance
   */
  router: ReturnType<typeof useRouter>
}

/**
 * Slider menu for mobile navigation
 */
export const SliderMenu = ({
  mainNavigation,
  topNavigation,
  pathname,
  router,
  isMenuOpened,
  handleCloseMenuLinkClick,
}: SliderMenuProps) => {
  const [selectedHeaderItem, setSelectedHeaderItem] = useState<string>()

  const t = useTranslations('mainNavigation')

  useBodyScrollLock(isMenuOpened)

  const mainNavigationItems = mainNavigation.itemsCollection

  const activeHeaderBarItem =
    mainNavigationItems?.items &&
    mainNavigationItems.items.find((item) => item?.name === selectedHeaderItem)

  const theme = useTheme()
  const logoLink = mainNavigation?.logoLink

  const handleBackButtonClick = () => {
    setSelectedHeaderItem(undefined)
  }

  const handleHeaderMenuItemClick = (name: string) => {
    const value = name !== selectedHeaderItem ? name : undefined
    setSelectedHeaderItem(value)
  }

  const selectedTopBarItem = useMemo(() => {
    if (!topNavigation) {
      return
    }

    const matchingItem = topNavigation.find((item) => {
      if (item) {
        const itemUrl = getLinkEntryUrl(item).toLowerCase()
        if (itemUrl === '/' && pathname === '/') {
          return true
        }
        return (
          pathname.toLowerCase().startsWith(itemUrl) &&
          (pathname.toLowerCase() === itemUrl || pathname.toLowerCase()[itemUrl.length] === '/')
        )
      }
      return false
    })

    return matchingItem ? matchingItem.name : topNavigation[0]?.name
  }, [topNavigation, pathname])

  const activeTopBarItem = topNavigation?.find((item) => item?.name === selectedTopBarItem)

  const menuItemLabel = (item: LinkEntry | ColumnMenuEntry) => {
    if (isTypeLink(item)) {
      return item.label
    }
    if (isTypeColumnMenu(item)) {
      return item.title
    }
    return ''
  }

  const changeTab = (e: LinkEntry | ColumnMenuEntry) => {
    if (isTypeLink(e)) {
      const url = getLinkEntryUrl(e)
      url && router.push(url)
      setSelectedHeaderItem(undefined)
      handleCloseMenuLinkClick()
    } else {
      if (e.name) {
        handleHeaderMenuItemClick(e.name)
      }
    }
  }

  return (
    <AnimatedBox
      width={{ default: '100%', s: '50%' }}
      display={{ xl: 'none' }}
      data-testid="mobile-menu-nav"
      $isOpen={isMenuOpened}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        background={theme.colors.backgroundPrimary}
        borderBottom={`1px solid ${theme.colors.borderPrimary}`}
      >
        <Link
          data-testid="mobile-fortum-logo-link"
          href={(logoLink && getLinkEntryUrl(logoLink)) ?? '/'}
        >
          <FortumLogo
            height={spacing.m}
            role="link"
            size="s"
            ph={spacing.xs}
            color={theme.colors.brandPrimary}
          />
        </Link>
        <Box display="flex">
          <IconButton
            status="plain"
            data-testid="mobile-close-nav-menu-button"
            aria-label={t('closeMenu')}
            color={theme.colors.textPrimary}
            height={spacing.l}
            width={spacing.l}
            icon={<Icon icon={IconCross} />}
            onClick={handleCloseMenuLinkClick}
          />
        </Box>
      </Box>
      {selectedHeaderItem && (
        <Box
          display="flex"
          p={spacing.xxxxs}
          alignItems="center"
          justifyContent="space-between"
          background={theme.colors.backgroundPrimary}
          borderBottom={`1px solid ${theme.colors.borderPrimary}`}
        >
          <IconButton
            status="plain"
            aria-label={t('back')}
            data-testid="mobile-nav-back-button"
            color={theme.colors.textPrimary}
            icon={<Icon icon={IconArrowLeft} />}
            onClick={handleBackButtonClick}
          />
          <ContentText alignSelf="center" justifySelf="center">
            {activeHeaderBarItem && menuItemLabel(activeHeaderBarItem)}
          </ContentText>
          <Box width="3rem" height="3rem" />
        </Box>
      )}
      <Box backgroundColor={theme.colors.backgroundPrimary}>
        {mainNavigationItems?.items?.map(
          (item) =>
            item && (
              <CatalogueContainer
                fields={item}
                key={item.name}
                visible={item?.name === selectedHeaderItem}
                device="mobile"
              />
            ),
        )}
        <>
          <Box pv={spacing.m} display={activeHeaderBarItem ? 'none' : undefined}>
            <ContentText
              fontSize={fontSizes.xl.default}
              ph={{ default: spacing.xs, xl: spacing.xxxxs }}
              fontWeight={fontWeights.medium}
              mb={spacing.xxs}
            >
              {activeTopBarItem?.label}
            </ContentText>
            {mainNavigationItems?.items?.map(
              (el, index) =>
                el && (
                  <StateButton
                    key={el.name}
                    color={theme.colors.textPrimary}
                    width="100%"
                    data-testid={`mobile-header-menu-item-${menuItemLabel(el)}`}
                    status="plain"
                    aria-label={`${menuItemLabel(el)}, ${{ number: index + 1 }}`}
                    onClick={() => changeTab(el)}
                    tabIndex={-1}
                    p={0}
                  >
                    <EffectBox $isActive={isTypeLink(el) && pathname === `/${el.page?.slug}`}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        ph={spacing.xs}
                        pv={spacing.xxs}
                        tabIndex={0}
                      >
                        <ContentText>{menuItemLabel(el)}</ContentText>
                        {isTypeColumnMenu(el) && el.columnsCollection?.items && (
                          <Icon icon={IconChevronRight} />
                        )}
                      </Box>
                    </EffectBox>
                  </StateButton>
                ),
            )}
          </Box>
        </>
      </Box>
      <Box display={!activeHeaderBarItem ? 'block' : 'none'}>
        <Box display="flex" flexDirection="column" pv={spacing.m}>
          {topNavigation
            ?.filter(Boolean)
            .map((item) => (
              <TopNavigationLink
                dataTestId={`mobile-top-nav-item-${item.label}`}
                key={item.name}
                isActive={selectedTopBarItem === item.name}
                label={item.label ?? ''}
                href={getLinkEntryUrl(item)}
              />
            ))}
        </Box>
      </Box>
    </AnimatedBox>
  )
}

const AnimatedBox = styled(Box)<{ $isOpen: boolean }>`
  ${({ theme, $isOpen }) => css`
    position: fixed;
    height: 100%;
    background-color: ${theme.colors.backgroundLightOnDark};
    top: 0;
    z-index: 500;
    overflow: auto;
    transition:
      transform 0.3s ease-in-out,
      visibility 0.3s ease-in-out;
    visibility: ${$isOpen ? 'visible' : 'hidden'};
    transform: ${$isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  `}
`

const EffectBox = styled(Box)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => css`
    ${$isActive
      ? css`
          div {
            border-left: ${spacing.xxxxs} solid ${theme.colors.brandPrimary};
            background-color: ${hex2rgba(theme.colors.brandPrimary, 0.05)};
          }
        `
      : ``}

    :hover,
    :focus,
    :active {
      background-color: ${hex2rgba(theme.colors.brandPrimary, 0.05)};

      p {
        color: ${theme.colors.textPrimary};
        background: none;
      }
    }
  `}
`
