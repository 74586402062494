import type { ContentTextProps } from '@fortum/elemental-ui'
import { ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

type HeadingProps = ContentTextProps & {
  titleLink: LinkEntry
}

export default function Heading({ titleLink, ...otherProps }: HeadingProps) {
  const theme = useTheme()
  const entryUrl = getLinkEntryUrl(titleLink)

  const contentText = (
    <ContentText
      fontSize={fontSizes.xl.default}
      ph={{ xl: spacing.xxs }}
      fontWeight={fontWeights.medium}
      {...otherProps}
    >
      {titleLink.label}
    </ContentText>
  )

  return (
    <>
      {entryUrl !== '' ? (
        <StyledLink
          href={entryUrl}
          color={theme.colors.textPrimary}
          data-testid={`title-link-${titleLink.label}`}
          noUnderline
        >
          {contentText}
        </StyledLink>
      ) : (
        contentText
      )}
    </>
  )
}
