import { INLINES } from '@contentful/rich-text-types'

import type { RichTextDocument } from '@/shared/components/RichText'
import { RichText } from '@/shared/components/RichText'
import { StyledLink } from '@/shared/components/StyledLink'

type LoggedInRichTextProps = {
  content?: RichTextDocument | null
  linkColor?: string
}

export const LoggedInRichText: React.FC<LoggedInRichTextProps> = ({ content, linkColor }) => (
  <RichText
    document={content}
    customOptions={{
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <StyledLink href={node.data.uri} target="_blank" fontSize="inherit" color={linkColor}>
            {children}
          </StyledLink>
        ),
      },
    }}
  />
)
