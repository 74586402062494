import { useLayoutEffect, useState } from 'react'

type WindowScroll = {
  x: number | undefined
  y: number | undefined
}

export const useWindowScroll = () => {
  const [state, setState] = useState<WindowScroll>({ x: undefined, y: undefined })

  useLayoutEffect(() => {
    const handleScroll = () => setState({ x: window.scrollX, y: window.scrollY })
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return state
}
